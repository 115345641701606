import { EntityState, createEntityAdapter } from "@ngrx/entity";
import { Property } from '../../models/property.model';
import { on, createReducer, Action } from '@ngrx/store';
import * as PropertyActions from './properties.actions';

/* Define Property State */
export interface PropertiesState extends EntityState<Property> {
    selectedPropertyId: number,
    savePropertyLoading: boolean,
    deleteLoading: boolean,
    connectFacebookLoading: boolean,
    connectWhatsappLoading: boolean,
}
const adapterProperty = createEntityAdapter<Property>();
const initialState: PropertiesState = adapterProperty.getInitialState({ 
    selectedPropertyId: 0,
    savePropertyLoading: false,
    deleteLoading: false,
    connectFacebookLoading: false,
    connectWhatsappLoading: false
});

const reducer = createReducer(
    initialState,
    on(
        PropertyActions.AllPropertiesLoaded,
        (state, {properties}) => (
            adapterProperty.setAll(properties, state)
        )
    ),
    on(
        PropertyActions.PropertySave,
        (state) => ({...state, savePropertyLoading : true})
    ), 
    on(
        PropertyActions.PropertySaveSuccess,
        (state, {property}) => (
            adapterProperty.upsertOne(property, {...state, savePropertyLoading: false})
        )
    ),
    on(
        PropertyActions.PropertySaveFailed,
        (state) => ({...state, savePropertyLoading : false})
    ), 
    on(
        PropertyActions.PropertyDeleteRequested,
        (state) => ({...state, deleteLoading : true})
    ), 
    on(
        PropertyActions.PropertyDeleteSucess,
        (state, { id }) => (adapterProperty.removeOne(id, {...state, deleteLoading: false}))),
    on(
        PropertyActions.PropertyDeleteFailed,
        (state) => ({...state, deleteLoading : false})
    ), 
    on(
        PropertyActions.PropertySelected,
        (state, {property_id}) => ({...state, selectedPropertyId : property_id})
    ),
    on(
        PropertyActions.PropertySaveLoading,
        (state, {isLoading}) => ({...state, savePropertyLoading : isLoading})
    ),     
    on(
        PropertyActions.PropertyDeleteLoading,
        (state, {isLoading}) => ({...state, deleteLoading: isLoading})
    ),
    on(
        PropertyActions.FacebookConnectRequest,
        (state) => ({...state, connectFacebookLoading : true})
    ), 
    on(
        PropertyActions.FacebookConnectSuccess,
        (state, { property }) => (adapterProperty.updateOne( property , {...state, connectFacebookLoading: false}))
    ),
    on(
        PropertyActions.FacebookConnectFailed,
        (state) => ({...state, connectFacebookLoading : false})
    ),
    on(
        PropertyActions.FacebookUnlinkSuccess,
        (state, { property }) => (adapterProperty.updateOne( property , {...state, connectFacebookLoading: false}))
    ),
    on(
        PropertyActions.WhatsappConnectRequest,
        (state) => ({...state, connectWhatsappLoading : true})
    ), 
    on(
        PropertyActions.WhatsappConnectSuccess,
        (state, { property }) => (adapterProperty.updateOne( property , {...state, connectWhatsappLoading: false}))
    ),
    on(
        PropertyActions.WhatsappConnectFailed,
        (state) => ({...state, connectWhatsappLoading : false})
    ),
    on(
        PropertyActions.WhatsappUnlinkSuccess,
        (state, { property }) => (adapterProperty.updateOne( property , {...state, connectWhatsappLoading: false}))
    )
);

export function propertiesReducer(state: PropertiesState | undefined, action: Action) {
    return reducer(state, action);
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal  
} = adapterProperty.getSelectors();
