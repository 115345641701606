import { Update } from '@ngrx/entity';
import { props, createAction } from '@ngrx/store';
import { FacebookPage, Property } from '../../models/property.model';

export const AllPropertiesRequested = createAction(
    '[Settings] All Properties Requested',
    props<{org_id: string}>(),
)

export const AllPropertiesLoaded = createAction(
    '[Settings] All Properties Loaded',
    props<{properties: Property[]}>(),
)

export const PropertySelected = createAction(
    '[Settings] Property Selected',
    props<{ property_id: number }>(),
)

export const PropertySave = createAction(
    '[Settings] Property Save',
    props<{property: Property }>(),
)

export const PropertySaveSuccess = createAction(
    '[Settings] Property Successfully Saved',
    props<{property: Property }>(),
)

export const PropertySaveFailed = createAction(
    '[Settings] Property Save Failed',
    props<{message: string;}>(),
)

export const PropertySaveLoading = createAction(
    '[Settings] Property is being Saved',
    props<{ isLoading: boolean }>(),
)

export const PropertyDeleteRequested = createAction(
    '[Settings] Property Delete Requested',
    props<{id: number;}>(),
)

export const PropertyDeleteSucess = createAction(
    '[Settings] Property Delete Success',
    props<{id: number}>(),
)

export const PropertyDeleteFailed = createAction(
    '[Settings] Property Delete Failed',
    props<{message: string;}>()
)

export const PropertyDeleteLoading = createAction(
    '[Settings] Property Delete Loading',
    props<{isLoading: boolean;}>(),
)

export const FacebookConnectRequest = createAction(
    '[Settings] Facebook Connect Request',
    props< {token: string ; user_id: string; property_id: number}>(),
)

export const FacebookConnectSuccess = createAction(
    '[Settings] Facebook Connect Success',
    props< {property: Update<Property>}>()
)

export const FacebookConnectFailed = createAction(
    '[Settings] Facebook Connect Failed',
    props< {message: string}>()
)

export const FacebookUnlinkRequest = createAction(
    '[Settings] Facebook Unlink Request',
    props< {page_id: string, property_id: number}>(),
)

export const FacebookUnlinkSuccess = createAction(
    '[Settings] Facebook Unlink Success',
    props< {property: Update<Property>}>(),
)

export const FacebookUnlinkFailed = createAction(
    '[Settings] Facebook Unlink Failed',
    props< {message: string}>(),
)


export const WhatsappConnectRequest = createAction(
    '[Settings] Whatsapp Connect Request',
    props< {code: string ; property_id: number, waba_id: string, phone_number_id: string}>(),
)

export const WhatsappConnectSuccess = createAction(
    '[Settings] Whatsapp Connect Success',
    props< {property: Update<Property>}>()
)

export const WhatsappConnectFailed = createAction(
    '[Settings] Whatsapp Connect Failed',
    props< {message: string}>()
)

export const WhatsappUnlinkRequest = createAction(
    '[Settings] Whatsapp Unlink Request',
    props< {number_id: string, property_id: number}>(),
)

export const WhatsappUnlinkSuccess = createAction(
    '[Settings] Whatsapp Unlink Success',
    props< {property: Update<Property>}>(),
)

export const WhatsappUnlinkFailed = createAction(
    '[Settings] Whatsapp Unlink Failed',
    props< {message: string}>(),
)
