export const environment = {
    version: "25.1.6",
    production: false,
    apiUrl: 'https://app-dev.getcharla.com',
    authTokenKey: 'authce9d77b308c149d5992a80073637e4d5',
    stripePublicKey: 'pk_test_51J8MxqFbO4HxWJEnlrn9wmqMABlFol5MpQVJPaqwrfmhGVXRvx8l2HrFaxCJtMiucRK2sMn4iO1xVqrHc4b30xU900epoUjEOA',
  
    storageUrl: 'https://charlaassets.blob.core.windows.net/',
    azureSASToken: {
      ticketsAttachments: '?si=access-policy-tickets-attachments&spr=https&sv=2022-11-02&sr=c&sig=ekJArejF112%2FbJNNwt1W8QuXgSqxGqVLHfy0OvPnRTM%3D',
      messagesAttachments: '?si=access-policy-messages-attachments&spr=https&sv=2021-12-02&sr=c&sig=PBMFul28RTpVG84Rwf32lNEBja6JjW9f%2FXCajipkq8Y%3D'
    },

    /* Session dursation in seconds */
    sessionDuration: 30 * 60,

  /* Global UI Settings */
  datatable_pagesize: 10,
  tickets_pagesize: 30,
  recent_conversations_pagesize: 30,

    ga: ''
  };
  